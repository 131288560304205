import React from 'react'
import './AfterSchool.scss'
import HeroBanner from '../../components/HeroBanner'
import Layout from '../../components/Layout'
import { Link, graphql } from 'gatsby'
import { ListGroup, ListGroupItem, Row, Col } from 'reactstrap'

const AfterSchool = ({ data }) => {
  return (
    <Layout>
      <div className="AfterSchool">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="OCRA After School"
          caption="The best after school program in they city"
        />
        <div className="container content">
          <p>Our after school program caters to children ages 7-12.</p>

          <p>
            Since its inception in 2015, OCRA has seen a huge shift in focus. If
            any business wants to be successful they must focus on moving
            forward, and to know where they are going one must first know where
            they have been. With over 11,000 kids coming through our doors in
            the last 3 years we have found one common interest, and that’s kids
            just want to be kids again.
          </p>

          <p>This includes activities such as:</p>
          <ListGroup>
            <ListGroupItem>Running</ListGroupItem>
            <ListGroupItem>Jumping</ListGroupItem>
            <ListGroupItem>Swinging</ListGroupItem>
            <ListGroupItem>Climbing</ListGroupItem>
          </ListGroup>

          <p>
            With our newly developed after school program, our goal is to help
            kids be kids again, by enjoying some of luxuries we had when growing
            up and not have the constant distractions that exist today cloud
            those abilities.{' '}
          </p>
          <p>
            Canadian youngsters are spending too much time in front of screens
            and not enough time participating in "heart-pumping physical
            activity," and the lack of exercise is damaging the health of their
            brains, according to a new report.
          </p>
          <p>
            The latest findings of the ParticipACTION report, released Tuesday,
            gave Canadian kids a D+ grade for their overall level of physical
            activity, marking a slight improvement from the past four reports
            where they have received a D-minus grade.
          </p>

          <p>
            Researchers found that only 35 per cent of children from the ages of
            5 to 17, and 62 per cent of kids from the ages of 3 to 4, are
            getting the recommended physical activity levels for their age
            groups. Both groups are also getting more screen time than is
            recommended.
          </p>

          <p>
            In addition to the negative effects this is having on cardiovascular
            health, muscle strength, sleep and weight, the report’s authors
            found that this lack of physical activity is having consequences in
            the classroom.
          </p>

          <p>
            They found that children who get the recommended amount of physical
            activity are more creative, better able to solve problems and less
            likely to make mistakes or suffer from mental health problems like
            depression and anxiety than their peers that do not. They also have
            better memories, longer attention spans and better self-esteem.
          </p>

          <p>
            "From increased cognitive skills to improved mental health, physical
            activity has profound impacts on kids’ brain health," said Mark
            Tremblay, the chief scientific officer of ParticipACTION and the
            director of the Children’s Hospital of Eastern Ontario Research
            Institute’s Healthy Active Living and Obesity Research Group. "So,
            for their brains’ sake, it’s time to get kids sitting less and
            moving more.”
          </p>

          <p>
            The benefits were seen in children with attention deficit
            hyperactivity disorder and autism spectrum disorders, too.
          </p>
          <p>
            "Children and youth who are least active or who have brain-based
            disabilities may have the most to gain," Tremblay said.
          </p>

          <p>
            The Canadian 24-Hour Movement Guidelines recommend that children
            from the ages of 5 to 17 spend at least 60 minutes day participating
            in physical activity. For kids from 3 to 4 years old, that number is
            at least 180 minutes.
          </p>

          <p>
            But the report notes that "some physical activity is better than
            none.”
          </p>

          <p>
            OCRA After School program will for sure get your kids the min amount
            of exercise needed. Our staff are Obstacle Course Certified, CPR
            certified, have a valid background check, and are also Autism
            certified but the Ausome group in Ottawa.
          </p>

          <Row>
            <Col xs="12" md={{ size: 6, offset: 3 }}>
              <img
                src="https://api.ocracademy.ca/wp/wp-content/uploads/2019/10/ausome.jpg"
                alt="Ausome Certification"
              />
              <sub>
                Our awesome certified staff after the Ausome certification.
              </sub>
            </Col>
          </Row>

          <h2>What is a typical day like in the OCRA After School program?</h2>

          <p>
            Kids are dropped off, we will be contacting the local schools that
            OCRA is in bus zones to arrange a drop off for your child or a they
            will be picked up by one of our branded OCR vehicles.
          </p>

          <p>
            Once dropped off they will get some unstructured play time, followed
            by a snack and homework time.{' '}
          </p>
          <p>
            4-5pm will be our Obstacle Fit Kids Class (
            <Link to="/obstaclefitkids">details here</Link>)
          </p>
          <p>
            Pick up is at 5pm, if you require a later pick up between 5-6pm an
            additional fee of $50 on your bi weekly payments will be applied.
            During this time the kids will be chilling in the cafe, playing
            games, doing arts and crafts, and would you believe it, socializing
            with their peers face to face and not on Fort Nite.{' '}
          </p>
          <h2>What schools are involved in the OCRA After School program?</h2>
          <ListGroup>
            <ListGroupItem>Pinecrest Public</ListGroupItem>
            <ListGroupItem>Knoxdale public School</ListGroupItem>
            <ListGroupItem>Manordale Public School</ListGroupItem>
            <ListGroupItem>Briargreen public school</ListGroupItem>
            <ListGroupItem>Ecole Elementary School</ListGroupItem>
            <ListGroupItem>Our Lady of Victoria</ListGroupItem>
            <ListGroupItem>St. John the Apostle</ListGroupItem>
          </ListGroup>
          <h2>What is the cost for the OCRA After School program</h2>
          <p>
            The cost is $200 bi-weekly (plus taxes and additional fees if
            applicable). It includes 5 kid classes, homework time and snacks.
            For pick up additional costs apply.{' '}
          </p>
          <h2>How do I register my child?</h2>
          <p>
            If you want to register your child for this awesome After School
            program, first you must have a valid{' '}
            <Link to="/waiver">waiver</Link> filled out then give us a{' '}
            <a href="tel:6137260909">call</a>. Let us know what school they are
            from and if they will require a pick up, or will be dropped off. We
            will be organizing pick ups for those that require it, please{' '}
            <Link to="/contact">contact us</Link> as restrictions do apply.
          </p>
          <Row>
            <Col xs="12" md="4">
              <img
                src="https://api.ocracademy.ca/wp/wp-content/uploads/2019/10/after-school-image-1.jpg"
                alt="Kids playing games during After School program"
              />
            </Col>
            <Col xs="12" md="4">
              <img
                src="https://api.ocracademy.ca/wp/wp-content/uploads/2019/10/ausome.jpg"
                alt="After School Program Group activity"
              />
            </Col>
            <Col xs="12" md="4">
              <img
                src="https://api.ocracademy.ca/wp/wp-content/uploads/2019/10/after-school-image-3.jpg"
                alt="Kids hard at work during OCRA After School program"
              />
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  )
}

export default AfterSchool

export const query = graphql`
  query {
    file(
      url: {
        eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
